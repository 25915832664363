@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --HEYG-primary-100: #d5e95b;
    --HEYG-primary-200: #c8e937;
    --HEYG-primary-300: #c0e13b;
    --HEYG-primary-400: #a2d600;
    --HEYG-primary-500: #AAD71F;
    --HEYG-primary-600: #98c900;
    --HEYG-primary-700: #97c919;
    --HEYG-primary-800: #5f9600;
    --HEYG-primary-900: #387700;
    --HEYG-corporate-accent: #ffc200;
    --HEYG-corporate-accent_2: #0c2345;
    --HEYG-dark-gray: #f9f8f3;
    --HEYG-light-gray: #eff2f7;
    --HEYG-primary-100: #d5e95b;
    --HEYG-primary-200: #c8e937;
    --HEYG-primary-300: #c0e13b;
    --HEYG-primary-400: #a2d600;
    --HEYG-primary-500: #AAD71F;
    --HEYG-primary-600: #98c900;
    --HEYG-primary-700: #97c919;
    --HEYG-primary-800: #5f9600;
    --HEYG-primary-900: #387700;
    --HEYG-corporate-accent: #ffc200;
    --HEYG-corporate-accent_2: #0c2345;
    --HEYG-dark-gray: #f9f8f3;
    --HEYG-light-gray: #eff2f7;
    --HEYG-error: #D12929;
    --HEYG-black: #1a1a1a;
    --HEYG-white: #ffffff;
    --bg-light: #f8f8f8;
    --corporate-gradient: linear-gradient(48deg, var(--HEYG-primary-500) 50%, var(--HEYG-corporate-accent));
}

@layer components {
    .mt-xlarge {
        @apply mt-10
    }

    .mt-large {
        @apply mt-8
    }

    .mt-medium {
        @apply mt-6
    }

    .mt-small {
        @apply mt-4
    }

    .mt-xsmall {
        @apply mt-3
    }

    .mt-2xsmall {
        @apply mt-2
    }

    .mt-3xsmall {
        @apply mt-1
    }

    .mb-xlarge {
        @apply mb-10
    }

    .mb-large {
        @apply mb-8
    }

    .mb-medium {
        @apply mb-6
    }

    .mb-small {
        @apply mb-4
    }

    .mb-xsmall {
        @apply mb-3
    }

    .mb-2xsmall {
        @apply mb-2
    }

    .mb-3xsmall {
        @apply mb-1
    }
}

h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
    color: var(--HEYG-black);
}

h2 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.1;
    color: var(--HEYG-black);
}

h3 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.1;
    color: var(--HEYG-black);
}

p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--HEYG-black);
}

ul {
    list-style-type: none;
    margin-left: 1.125rem;
    margin-bottom: 1rem;
}

li {
    font-size: 1rem;
}

.heyg-title-large {
    font-size: 2.875rem;
    font-weight: 700;
    color: var(--HEYG-black);
}

.heyg-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--HEYG-black);
}

.heyg-title-small {
    font-size: 1rem;
    font-weight: 700;
    color: var(--HEYG-black);
}

.heyg-headline {
    font-size: 1rem;
    font-weight: 400;
    color: var(--HEYG-black);
}

.heyg-text-small {
    font-size: 1rem;
    color: var(--HEYG-black);
}

.heyg-text-2xsmall {
    font-size: 0.75rem;
    color: var(--HEYG-black);
}

.heyg-btn-primary {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: var(--HEYG-black);
    background-color: var(--HEYG-primary-500);
    border-radius: 14px;
    cursor: pointer;
    padding: 1rem 1.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.heyg-btn-primary:hover {
    background-color: var(--HEYG-primary-700);
}

.cta-button {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: var(--HEYG-black);
    border-radius: 14px;
    cursor: pointer;
    padding: 1rem 1.5rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    margin-left: auto;
    margin-right: auto;
}

.cta-button:hover {
    background-color: #262626;
    /* Add any other hover styles if needed */
}

.heyg-btn-secondary {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.1;
    font-weight: bold;
    color: var(--HEYG-black);
    background-color: #fff;
    border: 1px solid var(--HEYG-corporate-accent_2);
    border-radius: 14px;
    cursor: pointer;
    padding: 1rem 1.5rem;
}

.heyg-btn-secondary:hover {
    background-color: var(--HEYG-light-gray);
}

.heyg-btn-tertiary {
    font-size: 1rem;
    text-decoration: underline;
    color: var(--HEYG-black);
    cursor: pointer;
}

.heyg-btn-tertiary:hover {
    color: var(--HEYG-corporate-accent);
}

.heyg-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--HEYG-primary-500);
    border: 1px solid transparent;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;
}

.heyg-btn-icon:hover {
    background-color: var(--HEYG-primary-700);
}

.heyg-btn--min-space {
    
}

.heyg-choice-box {
    width: 100%;
    font-size: 1rem;
    border-radius: 12px;
    border-width: 1px;
}

.heyg-choice-box:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* Base class for the container */
.heyg-container {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 1.75rem;
    background-color: var(--HEYG-white);
    margin-bottom: 2.5rem;
}

/* Modifier class for the corporate background */
.heyg-container--corporate {
    background-image: var(--corporate-gradient);
}

/* Modifier class for the filled background */
.heyg-container--filled {
    background-color: var(--HEYG-light-gray);
}

.heyg-container--mb-0 {
    margin-bottom: 0 !important;
}

/* Media Queries for Responsiveness: Non-mobile */
@media (min-width: 640px) {
    h1 {
        font-size: 2.875rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.625rem;
    }

    p {
        font-size: 1.25rem;
    }

    li {
        font-size: 1.25rem;
    }

    .heyg-title {
        font-size: 1.625rem;
    }

    .heyg-headline {
        font-size: 1.25rem;
    }

    .heyg-btn-tertiary {
        font-size: 1.25rem;
    }

    .heyg-btn--min-space {
        width: auto !important;
    }
}

.onItem:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.footer-link {
    font-size: 1rem;
    color: #1a1a1a;
}

.footer-link:hover {
    color: rgba(26, 27, 31, 0.75);
}

.corporate-gradient {
    background-image: var(--corporate-gradient);
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--HEYG-primary-500);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 